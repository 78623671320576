import { ReactComponent as Mobile } from './mobile.svg'
import { ReactComponent as Web } from './web.svg'
import { ReactComponent as NextGen } from './next-gen.svg'
import { ReactComponent as AI } from './ai.svg'

import { ReactComponent as ChevronRight } from './chevron-right.svg'
import { ReactComponent as ChevronLeft } from './chevron-left.svg'

const Services = {
  Web,
  Mobile,
  AI,
  NextGen,
}

export const Icons = {
  Services,
  ChevronRight,
  ChevronLeft,
}
